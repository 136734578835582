<!-- 自营外协司机编辑弹窗 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :close-on-click-modal="false"
      :title="isUpdate?'选择服务商':'选择服务商'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px">
      <div class="marginbottom">
        <el-select
          clearable
          v-model="form.subjection_id"
          placeholder="请选择"
          class="ele-fluid">
          <el-option
            v-for="(item) in service"
            :label="item.name"
            :value="item.value"/>
        </el-select>
      </div>
    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">提交
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

import {
  getcityList,
  Addvirtuals,
  Editvirtuals,
  Detalisvirtuals,
  facilitatorselect
} from "@/api/yunli";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        area: [
          { required: true, message: '请选择区域', trigger: 'change' },
        ],
        driver_count: [
          { required: true, message: '请输入司机数量', trigger: 'change' },
        ],
        frequency:[
          { required: true, message: '请输入频率', trigger: 'blur' },
        ],
        times:[
          { required: true, message: '请选择时间段', trigger: 'change' },
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 服务商
      service:[]

    };
  },
  watch: {

  },
  mounted() {
    this.getCity();
    this.getcommonfacilitator();
  },
  methods: {
    // 获取合作服务商列表
    getcommonfacilitator(){
      facilitatorselect().then(res => {
        this.service = res.data;
      })
    },

    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){

          }else {

          }
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">
  .marginbottom{
    margin-bottom: 20px;
  }
</style>
