<template>
  <!--虚拟定位-->
  <div>
    <div v-if="showEdit == false" style="padding: 20px;">
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
                :model="where"
                label-width="88px"
                class="ele-form-search"
                @keyup.enter.native="reload"
                @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键词:">
                    <el-input
                        clearable
                        v-model="where.keyword"
                        placeholder="请输入关键词"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                  </el-button>
                  <el-button @click="empty">清空</el-button>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="custom" style="margin-left: 20px">
                <el-button class="button" type="primary" @click="open(null)" v-auths="[`${$config.uniquePrefix}yunli:virtualPositioning:save`]">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle" >添加规则</span>
                </el-button>
              </div>
            </div>
            <!--<div>-->
            <!--  <el-button>导出数据</el-button>-->
            <!--  <el-button @click="ListSetup()">列表设置</el-button>-->
            <!--</div>-->
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >

              <template v-slot:start_time= "{ scope }">
                <span style="margin-right: 10px;">{{scope.row.start_time}}</span>
                <span>{{scope.row.end_time}}</span>
              </template>

              <template v-slot:status= "{ scope }">
                <el-tag v-if="scope.row.status === 0" type="success">已启用</el-tag>
                <el-tag v-else type="danger">已禁用</el-tag>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" class="rightmargin" @click="openEdit(scope.row)" v-auths="[`${$config.uniquePrefix}yunli:virtualPositioning:put`]">编辑</el-link>
                <el-popconfirm title="确定执行此操作吗？">
                  <el-link slot="reference" :underline="false" type="danger" class="rightmargin">禁用</el-link>
                </el-popconfirm>
                <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">
                  <el-link slot="reference" :underline="false" v-auths="[`${$config.uniquePrefix}yunli:virtualPositioning:delete`]">删除</el-link>
                </el-popconfirm>
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>

      <!--添加规则弹窗-->
      <Add :data="Addcurrent" :visible.sync="showAdd" @done="getList"/>

    </div>

    <!--详情-->
    <Edit v-if="showEdit == true" :data="current" :visible.sync="showEdit" @done="getList"/>

  </div>
</template>

<script>
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {Delvirtuals, virtuals} from "@/api/yunli";
//引入新增弹窗
import Add from './add.vue'
//引入地图窗口
import Edit from './edit.vue'



export default {
  // 组件生效
  components: {
    PackageSortElement,
    Edit,
    Add,
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        // {
        //   type: "selection",
        //   isShow: true
        // },
        {
          label: "系统编号",
          prop: "id",
          isShow: true,
          fixed: 'left'
        },
        {
          label: "名称",
          prop: "",
          isShow: true
        },
        {
          label: "服务商 ",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "时间段",
          prop: "start_time",
          slot: 'start_time',
          width: '200',
          isShow: true
        },
        {
          label: "司机数量",
          prop: "driver_count",
          isShow: true
        },
        {
          label: "虚拟坐标",
          prop: "",
          isShow: true
        },
        {
          label: "创建时间",
          prop: "create_time",
          width: '180',
          isShow: true
        },
        {
          label: "更新时间",
          prop: "update_time",
          width: '180',
          isShow: true
        },
        {
          label: "更新人",
          prop: "update_name",
          isShow: true
        },
        // {
        //   label: "刷新频率",
        //   prop: "frequency",
        //   isShow: true
        // },
        // {
        //   label: "分配服务商 ",
        //   prop: "subjection_name",
        //   isShow: true
        // },
        {
          label: "状态",
          prop: "status",
          slot: 'status',
          isShow: true
        },
        {
          label: "操作",
          width: "250",
          slot: "operationSlot",
          isShow: true,
          fixed: 'right'
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //地图
      current:null,
      showEdit:false,

      //编辑规则
      Addcurrent:null,
      showAdd:false,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取客户信息列表方法
    this.getList();
  },

  computed: {
    // 当前登录用户信息
    loginUser () {
      return this.$store.state.admin.userInfo
    },
  },

  methods:{
    // 获取列表
    getList(){
      virtuals(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    // 删除
    del(row){
      console.log(row);
      Delvirtuals(row.id).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList();
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },


    //点击详情
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },

    //点击添加规则
    open(row){
      this.Addcurrent = row;
      this.showAdd = true;
    },


  }

}
</script>

<style lang="scss" scoped>
  .rightmargin{
    margin-right: 15px;
  }
</style>
